.Button2 {
    cursor: pointer;
}

.Overlay {
    background-color: rgba(0, 0, 0, 0.55);
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    position: fixed;
    display: none;
    z-index: 5;
}

.Overlay.Show {
    display: block;
}

.leadModal {
    position: absolute;
    border-radius: 15px 0 0 0;
    right: -150vw;
    background-color: #fff;
    box-shadow: 0 0 4px 0px rgba(0, 0, 0, 0.15);
    bottom: 0;
    transition: all 0.3s ease-out;
    z-index: 10;
    height: 90vh;
    width: 96.5%;
}
.leadModal-2 {
    position: absolute;
    border-radius: 10px;
    right: -150vw;
    background-color: #fff;
    box-shadow: 0 0 4px 0px rgba(0, 0, 0, 0.15);
    bottom: 7%;
    transition: all 0.3s ease-out;
    z-index: 10;
    height: 81vh;
    width: 60%;
}
.leadModal-2-editDrop {
    position: absolute;
    border-radius: 10px;
    right: -150vw;
    background-color: #fff;
    box-shadow: 0 0 4px 0px rgba(0, 0, 0, 0.15);
    bottom: 7%;
    transition: all 0.3s ease-out;
    z-index: 10;
    height: 81vh;
    width: 46%;
}
.leadModal-3 {
    position: absolute;
    border-radius: 0 0 0 10px;
    right: -30vw;
    background-color: #fff;
    box-shadow: 0 0 4px 0px rgba(0, 0, 0, 0.15);
    bottom: -13.5vh;
    transition: all 0.5s ease-out;
    z-index: 10;
    padding: 10px;
    width: 12%;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.profileHead{
    font-weight: 500;
    padding: 5px;
    border-radius: 3px;
    cursor: pointer;
}
.logHead{
    font-weight: 500;
    padding: 5px;
    border-radius: 3px;
    cursor: pointer;
}
.profileHead:hover{
    background-color: var(--color-primary-light);
}
.logHead:hover{
    background-color: var(--color-primary-light);
}

.leadModal-2.leadShow-2 {
    right: 0;
    margin-right: 20%;
}
.leadModal-2-editDrop.leadShow-2-editDrop {
    right: 0;
    margin-right: 27%;
}
.leadModal-3.leadShow-3 {
    right: 0;
}
.leadModal.leadShow {
    right: 0;
}

.leadinfo-content {
    padding: 30px 50px;
    max-height: 100%;
    overflow-y: auto;
}

.leadinfo-content::-webkit-scrollbar {
    display: none;
}

.leadinfo-Top {
    background-color: var(--color-primary-main);
    border: 0;
    font-size: 18px;
    border-radius: 15px 0 0 0;
    padding: 15px 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #fff;

}
.leadinfo-Top-2 {
    background-color: var(--color-primary-main);
    border: 0;
    font-size: 18px;
    border-radius: 10px 10px 0 0;
    padding: 15px 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #fff;

}

.leadinfo-contentTop {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 10px 0 20px 0;
}

.leadinfoHr {
    border: 1px solid var(--color-light-grey);
    margin: 27px 0;
}

.leadinfo-contentTop-section {
    width: 100%;
}

.leadinfo-data1 {
    color: #000000;
    font-weight: 600;
}

.leadinfo-closebutton {
    cursor: pointer;
}

.leadInfo-heading {
    font-weight: 600;
}


.Alert {
    position: fixed;
    padding: 0.75rem 1.25rem;
    border: 1px solid transparent;
    top: 0;
    left: 0;
    color: #fff;
    background-image: linear-gradient(90deg,
            #ff9a8b 0%,
            #ff6a88 55%,
            #ff99ac 100%);
    margin: 0;
    width: 100%;
    text-align: center;
    z-index: 10;
}

.Alert:empty {
    padding: 0;
}

.accordion {
    background-color: var(--color-light-grey-2);
    color: var(--color-primary-main);
    cursor: pointer;
    padding: 18px;
    width: 100%;
    border: none;
    text-align: left;
    display: flex;
    align-items: center;
    justify-content: space-between;
    outline: none;
    transition: background-color 0.4s;
}

.accordion.activeLeadInfo,
.accordion:hover {
    background-color: var(--color-light-grey);
}

.accordion:after .accordion-icon{
    /* content: '\002B';
    color: #777;
    font-weight: bold;
    float: right;
    margin-left: 5px; */
    transition: transform 0.8s;
}

.leadInfoBox {
    border: 1px solid var(--color-light-grey-2);
}

.accordion.activeLeadInfo:after {
    transform: rotate(45deg);
}

.panel {
    padding: 0 20px;
    background-color: white;
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.2s ease-in-out;
}

.accordion-icon{
    transition: transform 0.5s ease;
    /* transform: rotate(180deg); */
}

.accordion-icon-active{
    /* transform: rotate(180deg);
    margin-top: 100px; */
}

.panel.activeLeadInfo {
    max-height: 500px;
    padding: 20px;
    /* Adjust this value according to your content */
}

.leadInfo-single{
    display: flex;
    width: 100%;
    align-items: center;
    padding: 10px 0;
    justify-content: flex-start;
    border-bottom: 1px solid var(--color-light-grey);
}

.leadInfoname{
    width: 40%;
}

.history1{
    display: flex;
    width: 100%;
    justify-content: space-between;
}
.history{
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-bottom: 100px;
    padding: 0 10px;
}

.history-circle{
    width: 20px;
}

.history-circle1{
    width: 92%;
    height: 19px;
    background-color: var(--color-secondary-2);
    border-radius: 50%;
    border: 2px solid var(--color-primary-middle);
}

.followUp-button{
    margin-bottom: 20px;
}

.history-circle2{
    max-width: 2px;
    margin: auto;
    height: 100%;
    background-color: var(--color-light-grey);
}

.history1-text{
    display: flex;
    align-items: self-start;
    justify-content: space-between;
    gap: 10px;
    margin-bottom: 30px;
}

.history1-left{
    display: flex;
    gap: 10px;
}
.history1-text{
    display: flex;
    flex-direction: column;
    gap: 10px;
    justify-content: flex-start;
    align-items: flex-start;
}
.history1-text3{
    display: flex;
    gap: 5px;
}
.history1-pdf{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
    font-size: 10px;
}
.history-name{
    font-size: 10px;
    font-weight: 600;
    color: var(--color-grey);
}
.history1-text2{
    color: var(--color-primary-middle);
    font-weight: 600;
}
.history1-text3-2{
    color: var(--color-grey);
    cursor: pointer;
}
.history1-right{
    font-size: 10px;
    font-weight: 600;
    color: var(--color-grey);
}

#leadsTable th {
    padding: 17px 30px 17px 17px;
    font-weight: 400;
    text-align: left;
    background-color: var(--color-light-grey);
    color: var(--color-primary-main);
}

.leadI-service1{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 27px 0;
}

.service1{
    padding:9px;
}

.service2{
    border: 1px solid var(--color-grey);
    color: var(--color-grey);
    padding: 10px;
    border-radius: 5px;
}

.services-li{
    display: flex;
    gap: 10px;
}

.historyHead{
    display: flex;
}

.historyHead1{
    cursor: pointer;
    margin-bottom: 20px;
}

.historyHead1:hover{
    color: var(--color-secondary-2);
}

.historyHr{
    height: 3px;
    width: 100%;
    background-color: transparent;
}

.historyHead1:hover .historyHr{
    background-color: var(--color-secondary-2);
}

.historyHead2{
    padding: 10px;
}

.historyHead3{
    color: var(--color-grey) !important;
}