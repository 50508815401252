body,
html {
    height: 100%;
    margin: 0;
}

.loginContainer {
    display: flex;
    height: 100vh;
}

.left-section {
    width: 40%;
    background-color: #FFFFFF;
    position: relative;
    margin-top: 5vh;
}

.left-section-r {
    width: 55%;
    margin-top: 5vh;
    background-color: #FFFFFF;
    position: relative;
}

.login-right-section {
    width: 60%;
    background-color: #FBF8FF;
}

.login-right-section-r {
    width: 45%;
    background-color: #FBF8FF;
}

.loginImage {
    height: 100vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.loginImg {
    max-width: 650px;
    object-fit: contain;
}

.loginform {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 0px 80px;
    height: 70vh;
}

.registerform {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 0px 80px;
    height: 70vh;
    gap: 4vh;
}
.logo {
    display: flex;
    align-items: center;
    justify-content: center; /* Centers the logo horizontally */
    width: 175px; /* Set desired width */
    height: auto; /* Ensures that the height adjusts automatically to maintain the aspect ratio */
}

.logo img {
    max-width: 100%; /* Ensures the image doesn't overflow */
    height: auto; /* Maintains the image aspect ratio */
}


h5 {
    font-weight: 900;
}

h6 {
    font-weight: 900;
}

.loginHeading {
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: flex-start;
}

h3 {
    font-weight: bold;
}

.label5text {
    color: var(--color-grey);
    font-weight: 500;
}

.loginFormInput {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 42vh;
    margin-top: 7%;
}

.buttoncontainer {
    display: flex;
    justify-content: center;
    margin: 5% 0 5% 0;
}

.downContainer {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.downcontainer1 {
    display: flex;
    flex-direction: row;
    gap: 10px;
    justify-content: center;
}

.downcontainer2 {
    display: flex;
    justify-content: center;
}

.errorLogin {
    color: red;
}

.error-border {
    border: 2px solid red !important;
}

.loader-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.799);
    /* Semi-transparent white */
    z-index: 9999;
    /* Ensure it's above other elements */
}

.register-input {
    display: flex;
    width: 100%;
    gap: 20px;
}

.password-wrapper {
    position: relative;
    width: 100%;
}

.eye-button {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    background: none;
    border: none;
    cursor: pointer;
    font-size: 1.2em;
    color: #333;
}

.eye-button:focus {
    outline: none;
}

@media only screen and (min-width: 1000px) and (max-width: 1100px) {
    .loginImg {
        height: 50vh;
    }
}

@media only screen and (min-width: 768px) and (max-width: 1020px) {
    .loginContainer {
        flex-direction: column;
    }

    .left-section {
        width: 100%;
        height: 60vh;
        margin: 0 0;
    }

    .left-section-r {
        width: 100%;
        margin-top: 0;
        height: 60vh;
        margin: 0 0;
    }

    .loginform {
        height: 48vh;
        margin-top: 6vh;
        margin-bottom: 6vh;
        padding: 0px 100px;
    }

    .registerform {
        height: 48vh;
        margin-top: 6vh;
        margin-bottom: 6vh;
        padding: 0px 100px;
        gap: 2vh;
    }

    .loginFormInput {
        height: 30vh;
    }

    .loginImage {
        height: 40vh;
    }

    .loginImg {
        /* object-fit: contain; */
        max-width: 500px !important;
        height: 36vh;
    }

    .login-right-section {
        width: 100%;
        height: 40vh;
    }

    .login-right-section-r {
        width: 100%;
        height: 40vh;
    }
}

@media only screen and (min-width: 1024px) and (min-height: 1200px) {
    .loginContainer {
        flex-direction: column;
    }

    .left-section {
        width: 100%;
        height: 60vh;
        margin: 0 0;
    }

    .left-section-r {
        width: 100%;
        margin-top: 0;
        height: 60vh;
        margin: 0 0;
    }

    .loginform {
        height: 48vh;
        margin-top: 6vh;
        margin-bottom: 6vh;
        padding: 0px 100px;
    }

    .registerform {
        height: 48vh;
        margin-top: 6vh;
        margin-bottom: 6vh;
        padding: 0px 100px;
        gap: 2vh;
    }

    .loginFormInput {
        height: 30vh;
    }

    .loginImage {
        height: 40vh !important;
    }

    .loginImg {
        /* object-fit: contain; */
        max-width: 500px !important;
        height: 36vh;
    }

    .login-right-section {
        width: 100% !important;
        height: 40vh;
    }

    .login-right-section-r {
        width: 100%;
        height: 40vh;
    }
}