#leadsTable {
    margin-top: 20px;
    width: 100%;
    border-radius: 10px;
    border: 1px solid var(--color-light-grey);
}

.table-wrapper{
    overflow-x: auto;
    max-width: 100%;
}

.roundedcorner {
    border-radius: 10px;
    border-spacing: 0;
}

#leadsTable tr:last-child td {
    border-bottom: none;
    /* Remove bottom border for last row */
}

.tablePageStatus{
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

#leadsTable td,
#leadsTable th {
    padding: 17px;
    border-bottom: 1px solid var(--color-light-grey);
    font-weight: 500;
    color: #16243D;
}

.actionsTable svg {
    cursor: pointer;
}

.serachDrop{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 20px;
    width: 30%;
}


#leadsTable th {
    padding: 17px;
    font-weight: 400;
    text-align: left;
    background-color: var(--color-light-grey);
    color: var(--color-primary-main);
}

.leadsTH{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 20px;
    flex-direction: row;
}

.actionsTableTh{
    width: fit-content;
}

.actionsTable {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-right: 5px !important;
    gap: 8px;
    height: 30px;
    text-align: center;
}

.tablePageStatus {
    color: var(--color-grey);
}

.leadsTableBottom {
    display: flex;
    justify-content: space-between;
    margin-top: 30px;
}


/* Dropdown Button */
.dropbtn {
    background-color: #FFFFFF;
    color: black;
    padding: 10px;
    font-size: 13px;
    border-radius: 5px;
    border: 2px solid var(--color-light-grey);
    cursor: pointer;
    margin-left: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
    
}

.dropbtn:hover,
.dropbtn:focus {
    background-color: #FFFFFF;
}

.dropdown {
    position: relative;
    display: flex;
    align-items: center;
    flex-direction: row;
    gap: 5px;
}

.dropdown-content {
    display: none;
    position: absolute;
    background-color: #FFFFFF;
    width: 50px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
    bottom: -390%;
    right: 2px;
}

.dropdown-content button {
    color: black;
    padding: 10px 10px;
    cursor: pointer;
    border: 1px solid var(--color-light-grey);
    text-decoration: none;
    display: block;
    background-color: #FFFFFF;
    width: 100%;
}

.dropdown-content button:hover {
    background-color: #f2f2f2;
}

.dropdown:hover .dropdown-content {
    display: block;
}

.dropdown:hover .dropbtn {
    background-color: #FFFFFF;
}

.leadsTableBottomLeft{
display: flex;
align-items: center;
justify-content: center;
gap: 50px;
}

.leadsTablePaginationButton{
    border: none;
    background-color: transparent;
}

.leadsTablePaginationButtonNumber{
    border-radius: 5px;
    font-size: 14px;
    border: none;
    padding: 5px 10px;
    color: #FFFFFF;
    background-color: var(--color-primary-main);
}
.leadsTablePaginationButtonNumber:disabled{
    background-color: var(--color-light-grey);
    color: var(--color-primary-main);
}

.leadsTablePaginationButtons{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 7px;
}