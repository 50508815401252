.testimonial{
    background-color: #FFFFFF;
    height: 12vh;
    width: 100%;
    border-radius: 15px;
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.test1{
    color: var(--color-grey);
    font-weight: 500;
}

.testLeft{
    display: flex;
    flex-direction: column;
    gap: 16px;
    align-items: self-start;
    justify-content: space-between;
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
    .testimonial{
        width: 40%;
        height: 9vh;
    }

}