.add-leads-form {
  display: flex;
  padding: 20px;
  flex-direction: column;
  /* align-items: flex-start; */
  gap: 20px;
  align-self: stretch;
  border-radius: 10px;
  background: var(--Primary, #fff);
  margin-bottom: 100px;
}

.add-leads-top {
  display: flex;
  padding: 20px 0px;
  align-items: center;
  justify-content: space-between;
  gap: 5px;
  align-self: stretch;
  width: 100%;
}

.add-leadssection {
  background-image: url("../../Assets/png/Ellipse53.png");
  width: 32px;
  height: 32px;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  color: var(--White, var(--Primary, #fff));
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.addleads-sectionname {
  color: var(--Fourth, #000);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  width: 115px;
}
.addleads-sectionname3 {
  color: var(--Third, #bcbacc);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  width: 150px;
}

.line {
  width: 60%;
  height: 0.5px;
  border: none;
  background: #bcbacc;
}

.add-leads-top2 {
  display: flex;
  align-items: center;
  /* justify-content: space-between; */
  gap: 5px;
  width: fit-content;
}

/* .add-leads-top2_1 {
    width: fit-content;
} */

.add-leadssection2 {
  background-image: url("../../Assets/png/Ellipse\ 54.png");
  width: 32px;
  height: 32px;
  flex-shrink: 0;
  color: var(--White, var(--Primary, #fff));
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  display: flex;
  align-items: center;
  justify-content: center;
}

.addleads-sectionname2 {
  color: var(--Third, #bcbacc);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  width: fit-content;
}

.add-leads-text {
  display: flex;
  padding: 8px 8px 8px 8px;
  align-items: flex-start;
  align-self: stretch;
  background: var(--Light-Greay, #d9d9d9);
}

.personal-text {
  color: var(--color-primary-middle);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.add-leads-inputbox {
  display: flex;
  height: 65px;
  align-items: flex-start;
  gap: 20px;
  align-self: stretch;
  width: 100%;
  margin-top: 26px;
  margin-bottom: 28px;
}

.add-leads-input-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 5px;
  flex: 1 0 0;
  align-self: stretch;
  width: 30%;
}

.add-leadlabel {
  color: var(--Fourth, #000);
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}



.add-leads-input {
  display: flex;
  padding: 10px 16px;
  align-items: center;
  gap: 12px;
  flex: 1 0 0;
  align-self: stretch;
  border-radius: 5px;
  border: 2px solid #ccc;
  background: #fafafa;
}

.add-leads-input:placeholder-shown {
  color: var(--Grey, #696e77);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.add-leads-addres {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 5px;
  flex: 1 0 0;
  align-self: stretch;
  margin-top: 10px;
}

.add-leads-aadresinput {
  display: flex;
  padding: 10px 16px;
  align-items: center;
  gap: 12px;
  flex: 1 0 0;
  align-self: stretch;
  border-radius: 5px;
  border: 1px solid #ccc;
  background: #fafafa;
}

.add-leads-location {
  display: flex;
  height: 65px;
  align-items: center;
  gap: 15px;
  align-self: stretch;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 10px;
}

.add-leads-input-town {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 5px;
  flex: 1 0 0;
  width: 12%;
  align-self: stretch;
}

.add-leads-locationinput {
  display: flex;
  padding: 10px 16px;
  justify-content: space-between;
  align-items: center;
  flex: 1 0 0;
  align-self: stretch;
  border-radius: 5px;
  border: 1px solid #ccc;
  background: #fafafa;
}

.add-leads-locationinput:placeholder-shown {
  color: var(--Grey, #696e77);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.add-leads-button {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  margin-top: 35px;
}

.smallButton {
  display: flex;
  padding: 10px 20px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  color: var(--White, var(--Primary, #fff));
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.add-leads-aadresinput:placeholder-shown {
  color: var(--Grey, #696e77);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

/* 2nd page css */

.add-leads-top1 {
  display: flex;
  padding: 20px 0px;
  align-items: center;
  gap: 15px;
  align-self: stretch;
}

.add-leads-topx {
  display: flex;
  align-items: center;
  gap: 5px;
}

/* 3rd page css */
.add-leads-service {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 5px;
  flex: 1 0 0;
  align-self: stretch;
}

.service-text {
  color: var(--Fourth, #000);
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.serivce-label {
  display: flex;
  padding: 10px 16px;
  align-items: center;
  font-size: 16px !important;
  gap: 12px;
  flex: 1 0 0;
  align-self: stretch;
  border-radius: 5px;
  border: 1px solid #ccc;
  background: #fafafa;
}

.serivce-label:placeholder-shown {
  color: var(--Grey, #696e77);

  /* Lable/Lable 4 */
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.add-leads-serviceremark {
  display: flex;
  height: 95px;
  flex-direction: column;
  align-items: flex-start;
  flex-shrink: 0;
  align-self: stretch;
}

.add-leads-servicebutton {
  display: flex;
  align-items: flex-end;
  gap: 20px;
  align-self: stretch;
}

.dropdown-leads {
  width: 100% !important;
}

.selectedNo {
  background-image: url("../../Assets/png/Ellipse53.png") !important;
}
.selectedNa {
  color: var(--Third, #000) !important;
}

.noSelectno {
  background-image: url("../../Assets/png/Ellipse 54.png") !important;
}

.noSelectna {
  color: var(--Third, #bcbacc);
}

.multiSelectContainer ul {
  width: 200px;
}

.optionListContainer {
  width: 200px;
}
