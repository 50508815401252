body,
html {
    height: 100%;
    margin: 0;
}

.forgotContainer {
    display: flex;
    height: 100vh;
}

.forgot-left-section {
    width: 40%;
    background-color: #FFFFFF;
    position: relative;
    margin-top: 25vh;
    margin-top: 25vh;
}

.forgot-right-section {
    width: 60%;
    background-color: #FBF8FF;
}

.loginImage {
    height: 100vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.loginImg {
    max-width: 650px;
    object-fit: contain;
}

.forgotForm {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 0px 80px;
    height: 50vh;
}

.logo {
    display: flex;
    gap: 5px;
    align-items: center;
}

h5 {
    font-weight: 900;
}

h6 {
    font-weight: 900;
}

.loginHeading {
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: flex-start;
}

h3 {
    font-weight: bold;
}

.label5text {
    color: var(--color-grey);
    font-weight: 500;
}

.forgotFormInput {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 28vh;
}

.downContainer {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.downcontainer1 {
    display: flex;
    flex-direction: row;
    gap: 10px;
    justify-content: center;
}

.downcontainer2 {
    display: flex;
    justify-content: center;
}

.errorLogin {
    color: red;
}

.error-border {
    border: 2px solid red !important;
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
    .forgotContainer {
        flex-direction: column;
    }

    .forgot-left-section {
        width: 100%;
        height: 60vh;
        margin: 0 0;

    }

    .forgotForm {
        height: 30vh;
        margin-top: 15vh;
        margin-bottom: 15vh;
        padding: 0px 100px;
    }

    .forgotFormInput {
        height: 17vh;
    }

    .loginImage {
        height: 40vh;
    }

    .loginImg {
        object-fit: contain;
        max-width: 600px;
        height: 36vh;
    }

    .forgot-right-section {
        width: 100%;
        height: 40vh;
    }
}