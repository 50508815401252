@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

:root {
  --color-primary: #008DD2;
  --color-primary-main: #2B2162;
  --color-primary-middle: #322672;
  --color-primary-light: #8C7CDA;
  --color-secondary: #AFCA1F;
  --color-secondary-2: #FEC600;
  --color-third: #BCBACC;
  --color-fourth: #16243D;
  --color-grey: #696E77;
  --color-light-grey: #D9D9D9;
  --color-light-grey-2: #F6F6F6;
  --color-gradient: 45deg, #015FC9 50%, #0CE0FF 50%;
  --color-black: #131313;
  --color-white: #FFFFFF;
}

input {
  font-family: 'Inter';
}

textarea {
  font-family: 'Inter';
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  /* Hide the incrementor buttons */
  -webkit-appearance: none;
  margin: 0;
  /* Remove any default margin */
}


* {
  margin: 0;
  padding: 0;
}

body {
  margin: 0;
  font-family: Inter !important;
  font-style: normal;
}

a {
  text-decoration: none !important;
}

a:hover {
  text-decoration: none !important;
  color: unset;
}

.bold {
  font-weight: 700;
}

.semi-bold {
  font-weight: 500;
}

h1 {
  font-size: 48px;
}

h2 {
  font-size: 42px;
}

h3 {
  font-size: 36px;
}

h4 {
  font-size: 30px;
}

h5 {
  font-size: 28px;
}

h6 {
  font-size: 20px;
}

.body1 {
  font-size: 14px;
  line-height: 22px;
}

.body2 {
  font-size: 14px;
  line-height: 22px;
}

label {
  font-size: 12px;
  line-height: auto;
  font-weight: 500;
  margin-bottom: 3px;
}

.label1 {
  font-size: 16px;
  line-height: 24px;
}

.label2 {
  font-size: 14px;
  line-height: 21px;
}

.label3 {
  font-size: 12px;
  line-height: 18px;
}

.label4 {
  font-size: 16px;
  line-height: auto;
}

.label5 {
  font-size: 14px;
  line-height: auto;
}

.navigator {
  cursor: pointer;
}

/* text editor*/
.rs-picker-default .rs-picker-toggle.rs-btn {
  padding-top: 7px;
  padding-bottom: 7px;
  width: 282px;
}

/* picked date align */
.rs-picker-default .rs-picker-toggle {
  position: relative;
  z-index: 5;
  padding-right: 67px;
  display: inline-block;
  outline: none;
  cursor: pointer;
  color: #575757;
  border: 1px solid #e5e5ea;
  padding-left: 44px;
}

/* calander align */
.rs-picker-toggle-caret {
  display: inline-block;
  margin-right: 240px;
  position: absolute;
  top: 8px;
  right: 12px;
  font-weight: normal;
  color: #8e8e93;
}

/* ok button style */
.rs-picker-toolbar-right-btn-ok {
  text-align: center;
  cursor: pointer;
  outline: 0;
  border: none;
  padding: 8px 12px;
  font-size: 14px;
  border-radius: 30px;
  color: #fff;
  background-color: #3498ff;
  width: 100px;
}

.rs-picker-menu .rs-calendar .rs-calendar-table-cell-content {
  padding-left: 0;
  padding-right: 0;
  border-radius: 21px;
  display: inline-block;
}