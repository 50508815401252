.input10{ 
    width: 100%;
    padding: 12px;
    font-size: 14px;
    border: 2px solid #CBD5E0;
    -webkit-transition: 0.5s;
    transition: 0.5s;
    border-radius: 5px;
    box-sizing: border-box;
}
.input11{ 
    width: 70%;
    padding: 12px;
    font-size: 14px;
    border: 2px solid #CBD5E0;
    -webkit-transition: 0.5s;
    transition: 0.5s;
    border-radius: 5px;
    box-sizing: border-box;
}

input::placeholder{
    color: var(--color-grey);
}

