.addServ-content {
    padding: 15px;
    height: 80%;
}
.addServ-content2 {
    padding: 30px;
    overflow-y: auto;
    height: 80%;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none; 
}

.addServ-content3{
    overflow-y: visible !important;
}

.searchWrapper {
    border: 2px solid #cccccc;
    border-radius: 4px;
    position: relative;
    padding: 5px;
    background-color: #FAFAFA;
    display: flex;
}

.chip {
    margin-bottom: 0;
    background: var(--color-primary-main);
}

.multiSelectContainer input :focus {
    background: transparent;
    border: none;
    margin-top: 3px;
    outline: none;
    border: none;
}

.multiSelectContainer input {
    background: transparent;
    border: none;
    margin-top: 3px;
    outline: none;
    border: none;
    padding: 5px 11px;
    font-weight: 500;
    font-size: 16px;
}

.label-1 {
    color: var(--color-fourth);
    font-weight: 500;
}

.multiSelectContainer li:hover {
    background: var(--color-light-grey);
}
.addServ-textarea {
    height: 100px;
    color: var(--color-grey);
    background-color: #FAFAFA;
    border: 2px solid #cccccc;
    border-radius: 4px;
    padding: 10px 16px;
    width: max-content;
    font-family: Inter !important;
    font-size: 16px;
    font-weight: 400;
    resize: none;
    width: 100%;
    box-sizing: border-box;
}

.addServ-input2 {
    margin: 20px 0;
}

.fu-submit-cancel {
    margin: 20px 0;
}

.addserv-table {
    margin-top: 20px !important;
}

.addServ-c1{
    display: flex;
    justify-content: space-between;
    gap: 20px;
}

.ci-fu-button{
    display: flex;
    gap: 5px;
}