.sidebar {
  background-color: var(--color-primary-main);
  padding-top: 20px;
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  transition: width 0.5s ease-in-out;
  display: flex;
  flex-direction: column;
  gap: 30px;
  margin-bottom: 20px;
  z-index: 999;
  overflow-y: auto;
  overflow-x: hidden;
  scrollbar-width: none;
}

.sidebarSmall {
  overflow-y: visible !important;
  overflow-x: visible !important;
  background-color: var(--color-primary-main);
  padding-top: 20px;
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  transition: width 0.5s ease-in-out;
  display: flex;
  flex-direction: column;
  gap: 30px;
  margin-bottom: 20px;
  z-index: 999;
}

.settings {
  display: none;
}

.master {
  display: none;
}

.sidebarTop {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.sidebarLogo {
  display: flex;
  font-weight: 900;
  color: #FFFFFF;
  gap: 6%;
  align-items: center;
  justify-content: center;
}

.sidebarSmallLogo {
  gap: 0;
}

.sidebarLogoRight {
  display: block;
  opacity: 100;
  transition: opacity 0.5s ease-in-out;
}

.sidebarSmallLogoRight {
  display: block;
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
  width: 0;
}

.sidebarLogoImg {
  z-index: 99;
  width: 90%;
}

.sidebarMenuHeading {
  padding: 0 10%;
  color: #FFFFFF;
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.menuHeadingSmall {
  display: flex;
  align-items: center;
  justify-content: center;
}

.sidebarMenuLine {
  border: 1px solid #FFFFFF;
  opacity: 0.3;
}

.sidebarMenuLine2 {
  padding: 0 10%;
}

.sidebarNavigator {
  display: flex;
  color: #FFFFFF;
  fill: #FFFFFF;
  padding: 10px 11%;
  align-items: center;
  gap: 10%;
  cursor: pointer;
  position: relative;
  margin-bottom: 8px;
}


.sidebarNavigator:hover {
  background-color: var(--color-primary-light);
  border-left: 9px solid var(--color-secondary-2);
  padding: 10px 7%;
}

#active {
  background-color: var(--color-primary-light);
  border-left: 9px solid var(--color-secondary-2);
  padding: 10px 7%;
}

.sidebaricon {
  display: flex;
  align-items: center;
}

.navigatorName1small {
  width: 0 !important;
}

.sidebarNavigatorSmall {
  display: block;
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
  width: 0;
}

.navigatorName1 {
  width: 60px;
  display: flex;
  align-items: flex-start;
}

.sidebarDropdown {
  transition: transform 0.5s ease;
}

.sidebaricon2 {
  visibility: hidden;
}

.rotatedDropdown {
  transform: rotate(180deg);
  /* Add your desired rotation degree */
}

.sidebarDropdownSmall {
  display: block;
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
  width: 0;
}

.collapseclose {
  display: none;
}

.sidebarNavigatorBig {
  display: block;
  opacity: 100;
  transition: opacity 0.5s ease-in-out;
}

.NavigatorSmall {
  gap: 0;
  justify-content: center;
}

.sidebarMaster {
  display: block;
  opacity: 100;
  transition: opacity 0.5s ease-in-out;
}

.tooltipSidebar {
  position: absolute;
  left: 100%;
  top: 12%;
  z-index: 999;
  display: flex;
  align-items: center;
  gap: 0;
  opacity: 0;
  pointer-events: none;
}

.tooltipInner {
  color: #FFFFFF;
  background-color: var(--color-primary-main);
  padding: 10px;
}

.left-arrow {
  width: 0;
  height: 0;
  border-bottom: 10px solid transparent;
  border-top: 10px solid transparent;

  border-right: 10px solid var(--color-primary-main);
}

.NavigatorSmall:hover .tooltipSidebar {
  opacity: 1;
}

.tooltipSidebar:hover {
  opacity: 1;
}

.NavigatorSmall:focus .tooltipSidebar {
  opacity: 1;
}

.hideSide {
  display: none;
}

/* Main.css */

.main {
  height: 100vh;
  position: fixed;
  right: 0;
  top: 0;
  transition: width 0.5s ease-in-out;
}


.layoutContainer {
  height: 100vh;
}

.searchDrop {
  display: flex;
  gap: 20px;
}

.same-no {
  font-size: 10px;
}

.wp-phone-2 {
  display: flex;
  gap: 5px;
}

.div3{
  position: relative;
  width: 100%;
  height: 100%;
}

@media (max-width: 1024px) {
  .layoutContainer {
    flex-direction: column;
  }

  .main {
    width: 94% !important;
  }

  .sidebarNavigator {
    justify-content: center;
  }

  .sidebar {
    width: 6% !important;
  }

  .navigatorName1 {
    display: none;
  }

  .sidebarLogoRight {
    display: none;
  }
}