.snackbar {
	min-width: 250px; /* Set a default minimum width */
	margin-left: -125px; /* Divide value of min-width by 2 */
	background-color: var(--darkBlack);
	color:var(--white);
	text-align: center; /* Centered text */
	border-radius: 8px; /* Rounded borders */
	padding: 16px; /* Padding */
	position: fixed; /* Sit on top of the screen */
	z-index: 999; /* Add a z-index if needed */
	left: 50%; /* Center the snackbar */
	bottom: 30px; /* 30px from the bottom */
	visibility: visible;
	display: flex;
	justify-content: space-between;
	align-items: center;
	-webkit-animation: fadein 0.5s, fadeout 0.5s 2.5s;
	animation: fadein 0.5s, fadeout 0.5s 2.5s;
}

.snackbar button{
	color:var(--white);
}

.snackbar__default{
	background-color: var(--darkBlack);
	color:var(--white);
}
.snackbar__error{
	background-color: rgb(182, 50, 50);
	color:var(--white);
}
.snackbar__success{
	background-color: green;
	color:var(--white);
}

@-webkit-keyframes fadein {
	from {bottom: 0; opacity: 0;}
	to {bottom: 30px; opacity: 1;}
}

@keyframes fadein {
	from {bottom: 0; opacity: 0;}
	to {bottom: 30px; opacity: 1;}
}

@-webkit-keyframes fadeout {
	from {bottom: 30px; opacity: 1;}
	to {bottom: 0; opacity: 0;}
}

@keyframes fadeout {
	from {bottom: 30px; opacity: 1;}
	to {bottom: 0; opacity: 0;}
}