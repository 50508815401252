.topbar {
  background-color: #FFFFFF;
  height: 5vh;
  padding: 20px 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
}

.toggleSidebarArrow {
  cursor: pointer;
}

.pageHeading {
  font-weight: 600;
  font-size: 24px;
}

.topbarHeading {
  display: flex;
  align-items: center;
  gap: 7px;
  border: none;
  background-color: #FFFFFF;
  cursor: pointer;
}

.lineTopbar {
  border: none;
  border-left: 2px solid var(--color-grey);
  height: 100%;
  opacity: 0.5;
}

.topbarRight {
  display: flex;
  align-items: center;
  gap: 20px;
  height: 100%;
}

.profileTopbar {
  display: flex;
  gap: 10px;
}

.profileNameRole {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 2px;
}
.homeTopbar{
  cursor: pointer;
}

.add-leads-date-table{
  padding: 7px 10px;
  border-radius: 5px;
  border: 2px solid var(--color-light-grey);
  background-color: #FAFAFA;
}

.profileRole {
  font-size: 9px;
  color: var(--color-grey);
  font-weight: 500;
}

@media (max-width: 1024px) {
  .toggleSidebarArrow {
    display: none;
  }

  .topbar {
    height: 4vh;
  }
  .homeTopbar{
    display: none;
  }
}