.reports-section {
    display: flex;
    padding: 20px;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
    align-self: stretch;
    border-radius: 10px;
    background: var(--Primary, #fff);
    margin-bottom: 20px;
}

.rs-picker-toggle-icon {
    /* Adjust the positioning of the calendar icon */
    right: 10px;
    /* Example value, adjust as needed */
    top: 50%;
    /* Example value, adjust as needed */
    transform: translateY(-50%);
    /* Center vertically */
}

.rs-picker-toggle-input {
    /* Adjust the width of the input field */
    width: 200px;
    /* Example value, adjust as needed */
}

.Dropdown-root {
    width: 100%;
    position: relative;
}

.report-inputsection {
    display: flex;
    height: 65px;
    align-items: flex-start;
    gap: 20px;
    align-self: stretch;

}

.report-inputbox {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 5px;
    flex: 1 0 0;
    align-self: stretch;
}

.report-inputname {
    color: var(--Fourth, #000);

    /* Lable/Lable */
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.report-searchbutton-container{
    width: 100%;
    display: flex;
    justify-content: flex-end;
}
.report-searchbutton {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 20px;
    /* align-self: stretch; */
}

.report-sectiondata {
    display: flex;
    padding: 20px;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
    align-self: stretch;
    border-radius: 10px;
    background: var(--Primary, #fff);
}

.report-exportbutton {
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
}


.section-data-top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}


.single-date-range-picker {
    position: relative;
    width: 100%;
}

.date-input {
    padding: 8px 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    width: 100%;
    background-color: #fafafa;
    color: var(--Grey, #696e77);
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    /* Fixed width for input */
    box-sizing: border-box;
}

.rdrDateRangePickerWrapper {
    position: absolute;
    right: 0;
    top: 40px;
    z-index: 2000;
}

.rdrStartEdge{
    color: var(--color-primary-middle) !important;
}

/* .rdrDayEndPreview,
.rdrDayStartPreview
    {
    border:1.5px solid var(--color-primary-middle) !important;
} */

.rdrDateDisplayItemActive {
    border-color: var(--color-primary-middle) !important;
}