.leads {
    background-color: #FFFFFF;
    padding: 20px;
    height: 100%;
    border-radius: 10px;
    /* width: 100%; */
    margin-bottom: 100px;
}

.topLeads {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.topLeadButtons {
    display: flex;
    align-items: center;
    gap: 10px;
}

.topLeadButton svg {
    fill: none;
}

.topLeadButtonAdd svg {
    fill: #FFFFFF;
}

.input-fu-1 {
    background-color: #FAFAFA;
    border: 2px solid #CCCCCC;
}

.eb {
    padding: 20px;
}

.eb1 {
    height: 130% !important;
}

.at-2 {
    height: 50px !important;
}

.wp-phone {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.taskTop {
    width: 100%;
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 30px;
    cursor: pointer;
}

.taskTop1 {
    width: 50%;
    display: flex;
    border-radius: 10px 0 0 10px;
    background-color: #FFFFFF;
    padding: 10px 0;
    justify-content: center;
}

.taskTop2 {
    width: 50%;
    padding: 10px 0px;
    border-radius: 0 10px 10px 0;
    background-color: #FFFFFF;
    display: flex;
    justify-content: center;
}

.taskTopSelected{
    background-color: var(--color-primary-main);
    color: #FFFFFF !important;
}

.check-perm{
    display: flex;
    gap: 8px;
    align-items: center;
    justify-content: flex-start;
}