.searchBox {
    width: 100%;
}

.searchInput {
    width: 100%;
    box-sizing: border-box;
    border: 2px solid var(--color-light-grey);
    border-radius: 4px;
    font-size: 12px;
    background-color: white;
    background-image: url('../../../Presentation/Assets/png/search-normal.png');
    background-position: 8px 8px;
    background-repeat: no-repeat;
    padding: 12px 10px 12px 40px;
}
.searchInput:focus{
    outline: none !important;
    border:2px solid var(--color-light-grey);
}

.searchBox input[type=text]:focus {
    border: 2px solid #c1c1c1;
}


