.main {
    background-color: #FAF9F2;
    z-index: 0;
}

.mainCom {
    padding: 30px;
    overflow-y: auto;
    overflow-x: hidden;
    max-height: 95vh;
    height: 100%;
}

.testimonials {
    display: flex;
    gap: 30px;
    z-index: 2;
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
    .testimonials {
        flex-wrap: wrap;
        justify-content: center;
    }

}