.guZdik{
    max-width: 100% !important;
    background-color: #8c7cda34;
    height: 15vh !important;
    border: 2px dashed var(--color-secondary-2) !important;
    margin: 0 20px !important;
    padding: 50px !important;
    display: flex;
    flex-direction: column;
}

.file-uploader{
    max-width: 100% !important;
    background-color: #8c7cda34;
    height: 15vh !important;
    border: 2px dashed var(--color-secondary-2) !important;
    margin: 0 20px !important;
    padding: 50px !important;
    display: flex;
    flex-direction: column;
    border-radius: 8px;
    cursor: pointer;
    align-items: center;
    gap: 5px;
}

.file-up{
    padding: 20px 20px 50px 20px !important;
    gap: 5px;
}

.close-fileUp{
    width: 100%;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
}

.ufile-modal{
    height: auto !important;
    bottom: 24% !important;
}