.proPic01{
    height: 200px;
    width: 200px;
    object-fit: cover;
    border-radius: 50%;
    border: 4px solid #ccc;
}

.proHead1{
    font-size: 20px;
    font-weight: 600;
    color: var(--color-primary-main);
}

.profilePic1{
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: center;
    justify-content: center;
}

.profile10{
    display: flex;
    justify-content: space-between;
    gap: 50px;
    width: 100%;
}

.proText{
    font-size: 15px;
    font-weight: 500;
    color: var(--color-grey);
    background-color: #FFFFFF !important;
}
.profileInfo{
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.profileEdit{
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}


.pro-1{
    margin-bottom: 40px !important;
}

#dropTable{
    width: 100%;
    margin-top: 10px;
    border-collapse: collapse;
    border-radius: 10px !important;
}

#dropTable tr{
    border: 0.5px solid var(--color-primary-middle);
    border-bottom: 0 ;
}

.dropPro{
    width: 20%;
    padding: 25px 10px;
    background-color: var(--color-light-grey);
    color: var(--color-primary-middle);
}

.firstRow{
    border-radius: 10px 0 0 0;
}

.lastRow{
    border-radius: 0px 0 0 10px;
}
.firstRowP{
    border-radius: 10px 10px 0 0;
    border: 0.5px solid var(--color-primary-middle);
}

.lastRowP{
    border-radius: 0px 0 10px 10px;
    border: 0.5px solid var(--color-primary-middle);
    border-bottom: 1px solid var(--color-primary-middle) !important;
}

.firstRowP3{
    border-radius: 0px 10px 0 0;
}

.lastRowP3{
    border-radius: 0px 0 10px 0;
}
.dropPro2{
    width: 100%;
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    padding: 10px;
    gap: 10px;
    align-items: center;
}
.dropPro3{
    width: 10%;
    display: flex;
    justify-content: center !important;
    padding: 10px;
    border-left: 1px solid var(--color-primary-middle);
    align-items: center;
    
}

.dropType{
    padding: 5px;
    border-radius: 4px;
}

.dropRow{
    display: flex;
    flex-direction: row;
    width: 100%;
}


.editdrop-table{
    max-height: 75%;
    overflow-y: auto;

}